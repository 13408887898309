import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../assets/less/when.less'

export default ({ data }) => {
  const metaDataTitle = data.wordpressPage.acf.metadata_title ? data.wordpressPage.acf.metadata_title : "Axel Olson"
  const metaDataDescription = data.wordpressPage.acf.metadata_description ? data.wordpressPage.acf.metadata_description : "Architect."
  const metaDataImg = data.wordpressPage.acf.metadata_image ? data.wordpressPage.acf.metadata_image.localFile.url : ''
  
  return (
    <Layout page={data.wordpressPage.title}>
      <SEO title={metaDataTitle}
           description={metaDataDescription}
           imageURL={metaDataImg}
      />
      <div className="when-page">
        <div className="content-section" dangerouslySetInnerHTML={{__html: data.wordpressPage.content}} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query {
  wordpressPage(title: {eq: "When"}) {
    id
    content
    title
    acf {
      project_lists {
        project_list_name
      }
      metadata_title
      metadata_description
      metadata_image {
        localFile {
          url
        }
      }
    }
  }
  }
`
